@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl md:text-5xl;
  }
  h2 {
    @apply text-2xl md:text-4xl;
  }
  h3 {
    @apply text-xl md:text-3xl;
  }
  h4 {
    @apply text-lg md:text-2xl;
  }
  h5 {
    @apply text-lg md:text-xl;
  }
  p,
  button,
  a,
  li {
    @apply text-base md:text-lg;
  }
  .subtitle {
    @apply text-lg md:text-xl;
  }
}

.logo-background-color {
  background-color: #00adb5;
}

.logo-background-color-lighter {
  background-color: #02d5e0;
}
.logo-border-color {
  border: 4px solid #00adb5;
}

.logo-border-bottom-color {
  border-bottom: 4px solid #00adb5;
}

.logo-text-color {
  color: #00adb5;
}

.blurred-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(163deg, #ff0037 0%, #3700ff 100%);
  filter: blur(15px); /* Adjust the blur radius as needed */
  z-index: -1;
  border-radius: inherit; /* Maintain the border-radius of the parent */
  opacity: 0;
  width: 92%;
  height: 92%;
  align-self: center;
  justify-self: center;
  transition: all 200ms ease-in-out;
}

.blurred-bg:hover::before {
  opacity: 1;
}

.rose-bg-hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(163deg, #ff0037 0%, #3700ff 100%);
  filter: blur(15px); /* Adjust the blur radius as needed */
  z-index: -1;
  border-radius: inherit; /* Maintain the border-radius of the parent */
  opacity: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  transition: all 200ms ease-in-out;
}

.rose-bg-hover:hover::before {
  opacity: 1;
}

.theme-bg-hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(163deg, #02d5e0 0%, #22019a 100%);
  filter: blur(15px); /* Adjust the blur radius as needed */
  z-index: -1;
  border-radius: inherit; /* Maintain the border-radius of the parent */
  opacity: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  transition: all 200ms ease-in-out;
}

.theme-bg-hover:hover::before {
  opacity: 1;
}

.two-sides-card {
  width: 100%;
  height: 100px;
  perspective: 1000px;
}

.two-sides-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.2s;
}

.two-sides-card:hover .two-sides-card-inner {
  transform: rotateY(180deg);
}

.two-sides-card-front,
.two-sides-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.two-sides-card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(0deg);
}

.two-sides-card-back {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
}
